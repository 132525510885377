import { createAction, resolveId } from '@wix/communities-blog-client-common';
import { getCategoryIds } from '@app/external/common/store/categories/categories-selectors';
import { type NormalizedPost, type PostV2 } from '@app/external/common/types';
import { normalizePosts } from '../../common/services/post-utils';
import { type PostPageThunkAction } from '../types';

export const FETCH_RELATED_POSTS_SUCCESS = 'relatedPosts/FETCH_SUCCESS';
export const FETCH_RELATED_POSTS_FAILURE = 'relatedPosts/FETCH_FAILURE';

export const fetchRelatedPostsRequest = createAction('relatedPosts/FETCH_REQUEST');
export const fetchRelatedPostsSuccess = createAction(
  FETCH_RELATED_POSTS_SUCCESS,
  (payload: NormalizedPost[], meta: { postId: string }) => payload,
  (payload, meta) => meta,
);
const fetchRelatedPostsFailure = createAction(FETCH_RELATED_POSTS_FAILURE);

export default function fetchRelatedPosts(
  post: NormalizedPost,
  postCount?: number,
): PostPageThunkAction {
  return (dispatch, getState, { request }) => {
    const url = '/v2/posts/publications';
    const params = { postId: resolveId(post)! };
    dispatch(fetchRelatedPostsRequest(params));

    const postIds = post.relatedPostIds ?? [];
    const postIdsToFetch = postCount ? postIds.slice(0, postCount) : postIds;
    const promise = request.post(url, { postsIds: postIdsToFetch }) as Promise<{
      posts: PostV2[];
    }>;

    return promise
      .then((response) => {
        const normalizedPosts = normalizePosts({
          state: getState(),
          posts: response.posts,
          origin: url,
          blogCategoryIds: getCategoryIds(getState()),
        });

        dispatch(fetchRelatedPostsSuccess(normalizedPosts, params));
      })
      .catch(() => dispatch(fetchRelatedPostsFailure()))
      .then(() => promise);
  };
}
