import type { Post, BlogMedia } from '@wix/ambassador-blog-v3-post/types';
// @ts-expect-error
import { isValidUrl } from '@wix/communities-blog-universal/dist/src/utils/is-valid-url';

export type WixCodePost = {
  _id: string | undefined | null;
  title: string | undefined | null;
  plainContent: string | undefined | null;
  publishedDate: Date | undefined | null;
  viewCount: number | undefined | null;
  likeCount: number | undefined | null;
  commentCount: number | undefined | null;
  lastPublishedDate: Date | undefined | null;
  coverImageDisplayed: boolean | undefined | null;
  timeToRead: number | undefined | null;
  pinned: boolean | undefined | null;
  featured: boolean | undefined;
  hashtags: string[] | undefined | null;
  excerpt: string | undefined | null;
  postPageUrl: string | undefined | null;
  coverImage: string | undefined | null;
  // for internal usage, undocumented fields
  tags: string[] | undefined;
  slug: string | undefined;
  categories: string[] | undefined;
  mainCategory: string | undefined;
  relatedPosts: string[] | undefined;
  paidPlans: string[] | undefined;
  author: string | undefined;
  richContent: Post['richContent'];
  language: Post['language'];
};

export const toWixCodePost = (platformizedPost: Post): WixCodePost => {
  return {
    _id: platformizedPost.internalId,
    title: platformizedPost.title,
    plainContent: platformizedPost.contentText,
    publishedDate: dateOf(platformizedPost.firstPublishedDate),
    viewCount: platformizedPost.metrics?.views,
    likeCount: platformizedPost.metrics?.likes,
    commentCount: platformizedPost.metrics?.comments,
    lastPublishedDate: dateOf(
      platformizedPost.lastPublishedDate || platformizedPost.firstPublishedDate,
    ),
    coverImageDisplayed: platformizedPost.media?.displayed,
    timeToRead: platformizedPost.minutesToRead,
    pinned: platformizedPost.pinned,
    featured: platformizedPost.featured,
    hashtags: platformizedPost.hashtags,
    excerpt: platformizedPost.excerpt,
    postPageUrl: platformizedPost.url?.path,
    coverImage: getCoverImageFromMedia(platformizedPost),

    // for internal usage, undocumented fields
    author: platformizedPost.memberId,
    categories: platformizedPost.internalCategoryIds,
    tags: platformizedPost.tagIds,
    slug: platformizedPost.slug,
    mainCategory: platformizedPost.internalCategoryIds?.[0],
    relatedPosts: platformizedPost.internalRelatedPostIds,
    paidPlans: platformizedPost.pricingPlanIds,
    richContent: platformizedPost.richContent,
    language: platformizedPost.language,
  };
};

const dateOf = (date?: Date): Date | null => (date ? new Date(date) : null);

export const getCoverImageFromMedia = (post: { media?: BlogMedia }): string | null => {
  const {
    id = null,
    width,
    height,
    url,
  } = post.media?.wixMedia?.image ?? (post.media?.wixMedia?.videoV2?.posters || [])[0] ?? {};
  const isWixImage = id && width && height;
  if (isWixImage) {
    return isValidUrl(id)
      ? id
      : `wix:image://v1/${id}/${id}#originWidth=${width}&originHeight=${height}`;
  } else if (url && isValidUrl(url)) {
    return url;
  } else {
    const { url: embedUrl } =
      post.media?.embedMedia?.thumbnail ?? post.media?.embedMedia?.video ?? {};
    return embedUrl && isValidUrl(embedUrl) ? embedUrl : null;
  }
};
