import { nthArg } from 'lodash';
import { queryPosts } from '@wix/ambassador-blog-v3-post/http';
import { Field, type Post } from '@wix/ambassador-blog-v3-post/types';
import { createAction, resolveId } from '@wix/communities-blog-client-common';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getDemoPosts } from '../../common/services/demo-posts';
import getEnvironment from '../../common/services/get-environment';
import { normalizePosts } from '../../common/services/post-utils';
import { getCategoryIds } from '../../common/store/categories/categories-selectors';
import { type NormalizedPost } from '../../common/types';
import { type PostPageThunkAction } from '../types';

export const FETCH_RECENT_POSTS_REQUEST = 'recentPosts/FETCH_REQUEST';
export const FETCH_RECENT_POSTS_SUCCESS = 'recentPosts/FETCH_SUCCESS';
export const FETCH_RECENT_POSTS_FAILURE = 'recentPosts/FETCH_FAILURE';

export const fetchRecentPostsRequest = createAction(FETCH_RECENT_POSTS_REQUEST);
export const fetchRecentPostsSuccess = createAction(
  FETCH_RECENT_POSTS_SUCCESS,
  nthArg(0),
  nthArg(1),
);
const fetchRecentPostsFailure = createAction(FETCH_RECENT_POSTS_FAILURE, nthArg(0), nthArg(1));

export default function fetchRecentPosts(post: NormalizedPost, postCount = 3): PostPageThunkAction {
  return async (dispatch, getState, { httpClient, platformApi, aggregatorRequest, flowAPI }) => {
    const params = { postId: resolveId(post) };
    dispatch(fetchRecentPostsRequest(params));
    const lang = getQueryLocale(getState());
    const editor = getEnvironment(platformApi).isEditorSegment;

    try {
      const response = await flowAPI.httpClient.request(
        queryPosts({
          query: {
            filter: {
              ...(lang ? { language: lang } : {}),
              ...(post.categoryIds?.length ? { categoryIds: { $hasSome: post.categoryIds } } : {}),
              id: { $ne: post.id },
            },
            paging: { offset: 0, limit: postCount },
          },
          fieldsets: [Field.METRICS],
        }),
      );

      let posts = response.data.posts as Post[];

      if (!posts.length && editor) {
        const fake = await getDemoPosts({
          httpClient,
          getState,
          dispatch,
          aggregatorRequest,
          platformApi,
          query: { pageSize: postCount + 1, excludeIds: [post.id!] },
        });

        posts = fake.posts as Post[];
      }

      const normalizedPosts = normalizePosts({
        state: getState(),
        posts,
        blogCategoryIds: getCategoryIds(getState()),
        origin: '/v3/posts',
      });

      dispatch(fetchRecentPostsSuccess(normalizedPosts, params));
    } catch (error) {
      dispatch(fetchRecentPostsFailure(undefined, params));
    }
  };
}
